import React from 'react';

import "../about/about.styles.scss";

import image1 from "../../../assets/about-image1.jpeg";
import image2 from "../../../assets/about-image2.jpeg";
import image3 from "../../../assets/about-image3.jpeg";
import image4 from "../../../assets/about-image4.jpeg";
import mgps1 from "../../../assets/mgps1.jpg";
import picture4 from "../../../assets/picture4.jpeg";
import bus from "../../../assets/Bus.jpeg";
import tank from "../../../assets/cylinder.jpeg";
import image5 from "../../../assets/about5.jpg";
import about8 from "../../../assets/about8.jpg";
import about9 from "../../../assets/about9.jpg";
import Footer from '../footer/footer.component';




const About = () => {
  return (
    <div className="about-page">
      <div className="about-hero">
        <h1>WHO WE ARE</h1>
      </div>
      <div className="about-content">
      <span className="about-card">
          <img src={image5} alt="engineering team" className='about-image' />  
          <p className='about-text'> 
            <b>I.C.E Oxygen Supply Services</b> is a medical oxygen distribution and maintenance company that is in the business of saving lives. 
            We add value through our distribution strategy and channels, and our comprehensive product lines and programs that make working with us incredibly easy.
          </p>
        </span>
        <span className="about-card1">
          <img src={image4} alt="engineering team" className='about-image-reverse' />  
          <p className='about-text-reverse'> 
            <b>Reliability, Efficiency & Professionalism (R.E.P)</b> are the values that make up the foundation on which <b>I.C.E. Oxygen </b> is built upon and are what we strive on. 
            We will continue to abide by these values as we develop and maintain relationships both with our internal and external customers. 
            We believe that no human should die from a lack of oxygen, and we have worked to make this belief a reality.
          </p>
        </span>

        <span className="about-card2">
          <img src={about8} alt="engineering team" className='about-image' />  
          <p className='about-text'> 
          I.C.E Oxygen Supply Services is a company specialized in Medical Gas Plant & Pipeline System installations, distribution of medical gases to public and private health facilities in Lagos state and across other states of the federation. 
          Our aim is to reduce mortality and morbidity from hypoxaemia in Nigeria by addressing the gaps in access to oxygen and other medical gases. Specifically, we will increase access to oxygen supply systems over the next ten years for hypoxaemia management. 
          </p>
        </span>

        <span className="about-card3">
          <img src={about9} alt="engineering team" className='about-image-reverse' />  
          <p className='about-text-reverse'> 
          Access to quality assured medical oxygen can mean the difference between life and death for patients. 
          The market is currently served poorly and inconsistently by a patchwork of local manufacturers and distributors. 
          We offer a regional, and ultimately national, network of medical sales professionals, which makes us the partner of choice for large, geographically diverse Hospital and Assisted Living (A.L.) chains, and makes us attractive to potential supplier partners.
          </p>
        </span>
        
        <span className="about-card4">
          <img src={mgps1} alt="" className='about-image'/>
          <span className='about-text'>
            <h1>Our Vision</h1>
            <p className="about-text-body">
              To be a foremost operator in the Nigerian Medical Gases Industry, pioneering aggressive and innovative people-driven and market oriented strategies for growth and profitability.
            </p>
          </span>
        </span>
        
        <span className="about-card5">
          <img src={tank} alt="" className='about-image-reverse'/>
          <span className='text'>
            <h1 className="text-head">Our Mission</h1>
          <p className="text-body">
          <b>I.C.E Oxygen Supply Services</b> is on a mission to ensure customer's satisfaction through prompt medical gases delivery, purity guarantee, cost effectiveness and continous services to eliminate the undesirable consequences of downtime in medical gas supply.
          </p>
        </span>
        </span>
        
      </div>

      <Footer />
    </div>
  )
}

export default About
