import React from 'react';

import "../trainings/trainings.styles.scss";
import Footer from '../footer/footer.component';
import trainings from "../../../assets/about-image3.jpeg";
import training2 from "../../../assets/training2.jpg";
import training3 from "../../../assets/training3.jpg";
import training4 from "../../../assets/training6.jpeg"
import training5 from "../../../assets/training1.jpg"


const Trainings = () => {
  return (
    <div className='trainings'>
       <div className="trainings-container">
        <h1>We offer Several Medical Gas Training Courses</h1>

        <main className="courses">
          <span className="first-course">
            <img src={training5} alt="" />
            
            <p className="course">
              <h4 className="course-title">
            Competent Person Medical Gas Pipeline Systems (MGPS) Installation Training 
            </h4>
              <ul>
               <b>Course Outline</b> 
                <li>Introduction to Medical Gas Pipeline System(MGPS)</li>
                <li>Fundamentals of MGPS</li>
                <li>Installation Techniques</li>
                <li>Reading and Interpreting Drawings</li>
                <li>Commissioning and Testing</li>
                <li>Safety Considerations</li>
              </ul>
            </p>
          </span>

          <span className="second-course">
            <img src={training3} alt="" />
            
            <p className="course">
              <h4 className="course-title">
            Competent Person Medical Gas Pipeline Systems (MGPS) Maintenance Training 
            </h4>
              <ul>
               <b>Course Content</b> 
                <li>Introduction to Medical Gas Pipeline System(MGPS)</li>
                <li>Safety Protocols and Personal Protective Equipment (PPE)</li>
                <li>Maintenance Procedures and Best Practices</li>
                <li>Hands-on Training Sessions</li>
                <li>Case Studies and Real-Life Scenarios</li>
                <li>Assessment and Certification</li>
              </ul>
            </p>
          </span>

          <span className="third-course">
            <img src={training2} alt="" />
            
            <p className="course">
              <h4 className="course-title">
                Medical Gas Technician MGPS 
              </h4>
              <ul>
               <b>Course Content</b> 
                <li>Introduction to Medical Gas Technology</li>
                <li>Nigerian Regulations and Standards for Medical Gas Systems</li>
                <li>Medical Gas System Components and Functionality</li>
                <li>Troubleshooting and Fault Diagnostics</li>
                <li>Emergency Response and Disaster Preparedness</li>
                <li>Hands-on Practical Training Sessions</li>
                <li>Case Studies and Real-Life Scenarios</li>
              </ul>
            </p>
          </span>

          <span className="fourth-course">
            <img src={trainings} alt="" />
            
            <p className="course">
              <h4 className="course-title">
              Quality Controller Medical Gas Pipeline Systems (MGPS)  
              </h4>
              <ul>
               <b>Course Content</b> 
                <li>Introduction to Medical Gas Pipeline Systems (MGPS)</li>
                <li>Quality Control Principles and Practices</li>
                <li>Nigerian Regulations and Standards for MGPS</li>
                <li>Testing Methods and Equipments</li>
                <li>Documentation and Record-Keeping</li>
                <li>Quality Assurance and Continuous Improvement</li>
                <li>Practical Hands-on Training Sessions</li>
                <li>Case Studies and Real-Life Scenarios</li>
              </ul>
            </p>
          </span>

          <span className="fifth-course">
            <img src={training4} alt="" />
            
            <p className="course">
              <h4 className="course-title">
                Medical Gas Training for Nurses
              </h4>
              <ul>
               <b>Course Content</b> 
                <li>Introduction to Medical Gases and Health Technical Memorandum HTM02</li>
                <li>Safety Protocols and Guidelines for Medical Gas Administration</li>
                <li>Types of Medical Gas Delivery Systems and Equipment</li>
                <li>Patient Assessment and Care Planning for Medical Gas Therapy</li>
                <li>Administration Techniques and Dosage Calculation</li>
                <li>Equipment Maintenance and Troubleshooting</li>
                <li>Emergency Response Procedures for Medical Gas Incidents</li>
                <li>Practical Hands-on Training Sessions</li>
                <li>Case Studies and Real-Life Scenarios</li>
              </ul>
            </p>
          </span>
        </main>
       </div>


      <Footer />
    </div>
  )
}

export default Trainings
