import './product.styles.scss'


const ProductItem = ({ product }) => {
    const { image, title, description } = product;
    return (
        <div  className="product-container">
        <div className='background-image' 
        style={{
         backgroundImage: `url(${image})`
        }} />
        <div className="product-body-container">
          <h2>{title}</h2>
          <p>{description}</p>
          
        </div>
      </div> 
    )
}

export default ProductItem