import React, { useState, useEffect }  from 'react';
import { Link, Outlet } from "react-router-dom";

import "../home/home.styles.scss";

import Directory from '../../directory/directory.component';
import About from '../about/about.component';


import product1 from "../../../assets/product1.jpg";
import product2 from "../../../assets/product2.jpg";
import product3 from "../../../assets/product3.png";
import product4 from "../../../assets/product4.png";
import product5 from "../../../assets/product5.png";
import product6 from "../../../assets/product6.png";

import image1 from "../../../assets/product4.png";
import image2 from "../../../assets/hero4.jpg";
import image3 from "../../../assets/hero3.jpg";

import testimonial1 from "../../../assets/testimonial1.jpg";
import testimonial2 from "../../../assets/testimonial2.jpg";
import testimonial3 from "../../../assets/testimonial3.jpg";

import client1 from "../../../assets/client1.png";
import client2 from "../../../assets/client2.jpg";
import client3 from "../../../assets/client3.jpg";
import client4 from "../../../assets/client4.jpeg";
import client5 from "../../../assets/client5.jpg";
import client6 from "../../../assets/client6.jpg";
import client7 from "../../../assets/client7.png";
import client8 from "../../../assets/client8.jpeg";
import client9 from "../../../assets/client9.jpg";
import client10 from "../../../assets/client10.jpg";
import client11 from "../../../assets/client11.png";
import client13 from "../../../assets/client13.jpg";
import  client14 from "../../../assets/client14.jpg";
import client15 from "../../../assets/image15.jpg";
import client16 from "../../../assets/client16.jpg";
import client17 from "../../../assets/client17.jpg" ;
import client18 from "../../../assets/client17.png";
import client119 from "../../../assets/client19.png";
import client20 from "../../../assets/client20.png";
import client21 from  "../../../assets/client21.png";

import Footer from '../footer/footer.component';
import PopupPage from '../../donate/PopUp.component';







const images = [
    { image: image1, text: 'Reliability, Efficiency and Professionalism R.E.P are Our Core Values.' },
    { image: image2, text: 'We believe that no Human should die from a lack of oxygen and we have worked to make this a reality.' },
    { image: image3, text: 'Access to quality assured Medical Oxygen can mean the difference between life and death for patients. We are on a mission to reduce mortality and morbidity from hypoxemia in Nigeria by addressing the gaps in access to oxygen.' }
];
const Home = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 6000);

        return () => clearInterval(interval);
    }, []);

    const products = [
        {
            id: 1,
            title: 'Oxygen supply in cylinders',
            description: "Cylinders serve as reservoir for high purity oxygen compressed under high pressure and require refilling from us once depleted.",
            image: product1,
        },
        {
            id: 2,
            title: 'Oxygen Concentrator Machines',
            description: "Oxygen concentrators are devices that concentrate oxygen from ambient air using pressure swing adsorption PSA .",
            image: product2,
        },
        {
            id: 3,
            title: 'Oxygen Analyzers',
            description: "A device that measures the level of oxygen in a system. Therefore determining if the level needs to be increased or not.",
            image: product3,
        },
        {
            id: 4,
            title: 'Oxygen Outlets and Fittings',
            description: "We offer a quality line of medical fittings used for medical grade gas.",
            imageUrl: '/assets/product4.png',
        },
        {
            id: 5,
            title: 'Flow Meters and Humidifiers',
            description: "A medical device for oxygen inhalation of first aid and hypoxic patients in the hospital.",
            imageUrl: '/assets/product5.png',
        },
        {
            id: 6,
            title: 'Pulse Oximeter',
            description: "A non-invasive method for monitoring a persons oxygen saturation.",
            imageUrl: '/assets/product6.png',
        },

    ]

    
        const [showForm, setShowForm] = useState(false);
      
        const toggleForm = () => {
          setShowForm(!showForm);
        };



  return (
    
    <main className='home'>
        <PopupPage />
        <div className="hero-container">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`hero-slide ${index === currentImageIndex ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${image.image})` }}
                >
                    <div className="overlay"></div>
                    <div className="hero-text">
                        <h1>{image.text}</h1>
                        {index === images.length - 1 && ( // Render button on the last slide
                            <Link to="/About">
                            <button className="hero-btn">Learn More</button></Link>
                        )}
                    </div>
                </div>
            ))}
        </div>
        <h1 className='product-head'>Products</h1>
      <Directory products={products} />

      <section className="testimonials-container">
        <h1 className='testimonial-head'>Happy Stories</h1>
        <section className="testimonials">
            
            <section className="testimonial">
                    <img src={testimonial1} alt=""  className="testimonial-image"/>
                    <section className="testimonial-content">
                        <p className='content'>"This company is a Life Saver! My mind is always at rest for medical Oxygen Supply to our hospital. 
                            We have known them for more than 5 years and no disappointment. 
                            They know their job so well and they are very reliable."</p>
                        <span className="author">Mrs. Kate</span>
                        <p className="location">Ifako Ijaye General Hospital</p>
                    </section>
            </section>
            <section className="testimonial">
                    <img src={testimonial2} alt=""  className="testimonial-image"/>
                    <section className="testimonial-content">
                        <p className='content'>"Fast on their feet! Easy to work with! Life Savers! 
                        Excellent performance! Prompt Source for Oxygen! Bio-Medical engineering expert! 24/7 services! I recommend this company anytime, any day."</p>
                        <span className="author">Mrs. Odunuga</span>
                        <p className="location">MCC ETI-OSA</p>
                    </section>
            </section>
            <section className="testimonial">
                    <img src={testimonial3} alt=""  className="testimonial-image"/>
                    <section className="testimonial-content">
                        <p className='content'>Outstanding service from start to finish! Swift and Seamless delivery! Prompt Source for Oxygen! Bio-Medical engineering expert! 24/7 services! I recommend this company anytime, anyday."</p>
                        <span className="author">Mr Paschal</span>
                        <p className="location">A.I.D. Hospital</p>
                    </section>
            </section>
        </section>
      </section>

      <section className="client-sections">
        <h1 className="client-head">Some of Our Awesome Clients</h1>
        <section className="client-section">
            <section className="client" >
                <img src={client1} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client2} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client3} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client4} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client9} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client14} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client15} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client17} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client18} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client20} alt="" className="client-image" />
            </section>
        </section>
        <section className="client-section">
            <section className="client">
                <img src={client5} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client6} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client7} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client8} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client10} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client11} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client13} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client16} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client119} alt="" className="client-image" />
            </section>
            <section className="client">
                <img src={client21} alt="" className="client-image" />
            </section>
        </section>
      </section>

 

      <Footer />
      <Outlet />        
    </main>
  )
}

export default Home
