import ProductItem from "../product-item/product-item.component";

import '../directory/directory.styles.scss';

import product1 from "../../assets/product1.jpg";
import product2 from "../../assets/product2.jpg";
import product3 from "../../assets/product3.png";
import product4 from "../../assets/product4.png";
import product5 from "../../assets/product5.png";
import product6 from "../../assets/product6.png";

const Directory = () => {
    const products = [
        {
            id: 1,
            title: 'Oxygen supply in cylinders',
            description: "Cylinders serve as reservoir for high purity oxygen compressed under high pressure and require refilling from us once depleted.",
            image: product1,
        },
        {
            id: 2,
            title: 'Oxygen Concentrator Machines',
            description: "Oxygen concentrators are devices that concentrate oxygen from ambient air using pressure swing adsorption PSA .",
            image: product2,
        },
        {
            id: 3,
            title: 'Oxygen Analyzers',
            description: "A device that measures the level of oxygen in a system. Therefore determining if the level needs to be increased or not.",
            image: product3,
        },
        {
            id: 4,
            title: 'Oxygen Outlets and Fittings',
            description: "We offer a quality line of medical fittings used for medical grade gas.",
            image: product4,
        },
        {
            id: 5,
            title: 'Flow Meters and Humidifiers',
            description: "A medical device for oxygen inhalation of first aid and hypoxic patients in the hospital.",
            image: product5,
        },
        {
            id: 6,
            title: 'Pulse Oximeter',
            description: "A non-invasive method for monitoring a persons oxygen saturation.",
            image: product6,
        },

    ]
    return (
        <div className="directory-container">
            {products.map((product) =>(
                
                <ProductItem key={product.id} product={product} />
            ))}
        </div>      
    )
}

export default Directory