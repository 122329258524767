import React from 'react';
import "../MGPS/MGPS.styles.scss";
import mgps from "../../../assets/mgps.jpg";
import mgps1 from "../../../assets/mgps1.jpg";
import mgps2 from "../../../assets/distributed.jpg";
import mgps3 from "../../../assets/mgps3.jpg";
import mgps4 from "../../../assets/mgps4.jpg";
import mgps5 from "../../../assets/mgps5.jpg";
import mgps6 from "../../../assets/mgps6.jpg";
import mgps9 from "../../../assets/mgps9.jpg";
import cc from "../../../assets/cc.jpg"
import Footer from '../footer/footer.component';



const MGPS = () => {
  return (
    <div className="mgps">
      <div className="mgps-hero">
         <h1 className="mgps-head-text">
        Medical Gas Pipeline System (MGPS)
        </h1> 
      </div>
     <div  className="mgps-container">
    <span className="mgps-card">
      <img src={mgps2} alt="" className="mgps-image" />
      <span className="mgps-text">
      <b>I.C.E Oxygen Supply Services </b> has unparalleled experience in the design, installations and maintainance of Medical Gas Pipeline Systems.
      The Medical Gas Pipeline system is an Infrastructure developed to transport Medical Gases from its source to end point for use safely and in best quality.
      <blockquote>
        "Our System Supports the stable supply of medical gases more safely and more securely."
      </blockquote>
      </span>
    </span>

    <span className="mgps-card-reverse">
      <img src={mgps4} alt="" className="mgps-image-reverse" />
        <p className="mgps-text-reverse">
          The Medical gases used in a Hospital are life-supporting elements that give direct influence in maintaining the life of a patient. 
          Therefore at the section where the medical gases are used, the medical gas must be clean, highly pure and supplied under stable pressure.
          Our Medical Gas System has cleared those regulations and standards as well as passing our strict company standard.
        </p>
    </span>
    
    <span className="mgps-card1">
      <img src={mgps5} alt="" className="mgps-image1" />
      <p className="mgps-text1">
        Our Medical Gas System has thorough colour coordination according to the kind of Gas, an audio-visual monitoring system capable of checking the situation and a device to prevent cross connection at medical gas outlet based on the concept, "More safely" and "More Securely".

      </p>
    </span>

    <span className="mgps-card-reverse1">
      <img src={cc} alt="" className="mgps-image-reverse1" />
        <p className="mgps-text-reverse1">
        We take pride in Our Exceptional Customer Service. Our Knowledgeable customer service representatives are here to help you match the right product to your specific needs.
        We are completely commited to Satisfying Our Most important customer.....<b>You!</b>
        </p>
    </span>


        <span className="mgps-card2">
          <img src={mgps9} alt="" className="mgps-image2"/>
          <ul className='mgps-text2'>
            <p className="mgps-text2">Our team of dedicated and experienced technical representatives are ready to provide the services you need at a moment's notice </p>
            <li className="mgps-subtext">Preventive Maintenance</li>
            <li className="mgps-subtext">Certification</li>
            <li className="mgps-subtext">Repairs</li>
            <li className="mgps-subtext">General Service Requests</li>
            <li className="mgps-subtext">Testing and Verification Services</li>
            <li className="mgps-subtext">Inspection Services</li>
          </ul>
        </span>
      </div>
      <Footer />
    </div>
  )
}

export default MGPS;
