import React, { useState, useEffect } from 'react';
import '../donate/PopUp.styles.scss'; // Import the SCSS file for styling
import { Link } from 'react-router-dom';

import image1 from "../../assets/donate-image.jpeg"

const PopupPage = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 5 * 60 * 100); // 5 minutes in milliseconds

    return () => clearTimeout(popupTimer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>
              &times;
            </button>
            <img src={image1} alt="donate" />
            <h2>Will you like to support our cause?</h2>
            <p>Do you want to donate Oxygen to save lives?</p>
            <Link to="https://wa.link/33fywd" target="_blank"><button className="donate-btn">Donate</button></Link>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupPage;
