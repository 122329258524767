import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import { Outlet, Link, NavLink } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { UserContext } from '../../../contexts/user.context';

import '../navigation/navigation.styles.scss';
import About from '../about/about.component';
import MGPS from '../MGPS/MGPS.component';
import Trainings from '../trainings/trainings.component';

const Navigation = ({ className }) => {
  const { currentUser } = useContext(UserContext);
  const [showNav, setShowNav] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowNav(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/" className="logo">
          <img src={Logo} alt="Company Logo" />
        </Link>
      </div>
      <div className="hamburger-menu" onClick={toggleNav}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div ref={menuRef} className={`full-menu ${showNav ? 'show-nav' : ''}`}>
        <ul className="nav-links">
          <li>
            <Link to="/" className="link">
              Home
            </Link>
          </li>
          <li>
            <NavLink to="/About" className="link">
              About
            </NavLink>
          </li>
          <li className="services">
            Services
            <ul className="sub-links">
              <li>
                <NavLink to="/MGPS" className="sub-link">
                  MGPS
                </NavLink>
              </li>
              <li>
                <NavLink to="/Trainings" className="sub-link">
                  Trainings
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
        <Link to="https://wa.link/z4ujpl" target="_blank">
          <button className="btn">Contact Us</button>
        </Link>
      </div>
    </nav>
  );
};

export default Navigation;
