import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navigation from './components/routes/navigation/navigation.component';
import Home from './components/routes/home/home.component';
import About from './components/routes/about/about.component';
import MGPS from './components/routes/MGPS/MGPS.component';
import Trainings from './components/routes/trainings/trainings.component';


const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        {/* <Route path='/' element={<Navigation />}> */}
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path='/MGPS' element={<MGPS />} />
          <Route path='/Trainings' element={<Trainings />}/>
        {/* </Route> */}
        
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
